import { CREATE_TEAM, JOIN_TEAM, MY_CLASS, MY_TEAM, SHOW_SCHOOL_CLASS_CODE, SHOW_TEAM_CODE } from './me.names';
import { LAYOUT_SIMPLE } from '@/router/core.constants';

const profilePage = () => import('@/pages/profile.vue');
const profile = () => import('@/components/profile/Profile.vue');
const myClass = () => import('@/pages/myClass.vue');
const activity = () => import('@/pages/activity.vue');
const oldClasses = () => import('@/pages/oldClasses.vue');
const createClass = () => import('@/pages/createClass.vue');
const showSchoolClassCode = () => import('@/pages/me/showSchoolClassCode.vue');
const showTeamCode = () => import('@/pages/me/showTeamCode.vue');
const myTeam = () => import('@/pages/me/myTeam.vue');
const joinTeam = () => import('@/pages/me/joinTeam.vue');
const createTeam = () => import('@/pages/me/createTeam.vue');

export default [
  {
    path: '/me',
    component: profilePage,
    children: [
      { path: 'profile', name: 'profile', component: profile, meta: { isProfile: true, matomoUrl: '/me/profile' } },
      {
        path: 'class',
        alias: 'my-class',
        name: MY_CLASS,
        component: myClass,
        meta: { isProfile: true, matomoUrl: '/me/class' },
      },
      { path: 'activity', name: 'activity', component: activity, meta: { isProfile: true, matomoUrl: '/me/activity' } },
      { path: '', name: 'profile-activity', component: activity, meta: { isProfile: true, matomoUrl: '/me/activity' } },
      {
        path: 'old-classes',
        name: 'old-classes',
        component: oldClasses,
        meta: { isProfile: true, matomoUrl: '/me/old-classes' },
      },
      {
        path: 'class/create',
        alias: 'create-class',
        name: 'create-class',
        component: createClass,
        meta: { layout: LAYOUT_SIMPLE, matomoUrl: '/me/class/create' },
      },
      {
        path: 'class/code',
        alias: 'show-code',
        name: SHOW_SCHOOL_CLASS_CODE,
        component: showSchoolClassCode,
        meta: { layout: LAYOUT_SIMPLE, matomoUrl: '/me/class/code' },
      },
      { path: 'team', name: MY_TEAM, component: myTeam, meta: { isProfile: true, matomoUrl: '/me/team' } },
      {
        path: 'team/join',
        name: JOIN_TEAM,
        component: joinTeam,
        meta: { isProfile: true, layout: LAYOUT_SIMPLE, matomoUrl: '/me/team/join' },
      },
      {
        path: 'team/create',
        name: CREATE_TEAM,
        component: createTeam,
        meta: { isProfile: true, layout: LAYOUT_SIMPLE, matomoUrl: '/me/team/create' },
      },
      {
        path: 'team/code',
        name: SHOW_TEAM_CODE,
        component: showTeamCode,
        meta: { layout: LAYOUT_SIMPLE, matomoUrl: '/me/team/code' },
      },
    ],
  },
];
